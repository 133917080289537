import BaseText from "../../../../../components/ui/BaseText";
import BaseContainer from "../../../../../components/ui/BaseContainer";
import { getFontAwesomeIcon } from "../../../../../utils";
import { BundleItemDataProps } from "./types";
import placeholderImage from "../../../../../assets/empty-image.png";
import { usePrivilegeCheck } from "../../../../../hooks";
import { faLoveseat } from "@fortawesome/pro-duotone-svg-icons";

const BundleItem: React.FC<BundleItemDataProps> = ({ item, renderBadges }) => {
	const { isAdmin } = usePrivilegeCheck();

	const renderCorrectPrice = () => {
		if (item?.PriceTypes?.name === "Each" && isAdmin) {
			return `$ ${item.price}`;
		} else if (item?.PriceTypes?.name === "Lease" && isAdmin) {
			return `$ ${item.price}/mo`;
		} else if (item?.PriceTypes?.name === "Lease" && !isAdmin) {
			return `$ ${item._extPriceBundleLease}/mo`;
		} else if (item?.PriceTypes?.name === "Each" && !isAdmin) {
			return `$ ${item.price}`;
		}
	}

	return (
		<div key={item.uuid}>
			<div className="ml-20">
				<BaseContainer
					key={item.uuid}
					columnContainerClasses="px-2 py-1 rounded-lg w-full border-transparent"
					columnShape="semi-rounded"
					isDisabled={false}
				>
					<div className="flex justify-between items-center w-full">
						<div className="flex">
							<div
								className={`mr-5 rounded-lg flex items-center justify-center h-full w-16 `}
							>
								{/* decorative image */}
								<img src={placeholderImage} alt="" />
							</div>
							<div className="flex flex-col text-left">
								<div className="flex gap-2">
									{item?.BundleItems?.isRequired === "1" && (
										<div className="flex flex-row gap-2">
											{renderBadges("Required")}
										</div>
									)}
									{item?.BundleItems?.isRequired === "0" && (
										<div className="flex flex-row gap-2">
											{renderBadges("Add-on")}
										</div>
									)}
									{item?.PriceTypes?.name === "Lease" && (
										<div className="flex flex-row gap-2">
											{renderBadges("Computer Support")}
										</div>
									)}
								</div>
								<BaseText
									text={item?.Items?.description || "-"}
									size="text-base"
									color="text-gray-700"
									fontFamily="font-inter-medium font-medium"
									tag="h3"
									additionalClasses="mb-1"
								/>
								<BaseText
									text={item?.Items?.partNumber || "-"}
									size="text-sm"
									color="text-primary-text"
									fontFamily="font-inter-regular"
									tag="p"
									additionalClasses="mb-1"
								/>
								<div className="flex flex-row gap-2">
									<BaseText
										text={`${renderCorrectPrice()}`}
										size="text-sm"
										color="text-gray-700"
										fontFamily="font-inter-regular"
										tag="p"
									/>
									<BaseText
										text={
											`Qty: ${parseInt(
												item?.quantity,
												10
											)}` || "—"
										}
										size="text-sm"
										color="text-gray-700"
										fontFamily="font-inter-medium"
										tag="p"
									/>
								</div>
							</div>
						</div>
					</div>
				</BaseContainer>
			</div>
		</div>
	);
};

export default BundleItem;
