import React from "react";
import { motion } from "framer-motion";
import BaseText from "../../../../../components/ui/BaseText";
import { getFontAwesomeIcon } from "../../../../../utils";
import { Link } from "react-router-dom";
import StatusBadge from "./badgeColorConversion";
import { convertStatus } from "./badgeColorConversion";

interface ApprovalContentHeaderProps {
	dateOrder: string;
	orderNumber: string;
	orderStatus: string;
	submittedBy: string;
	hasNavigationCompassEditPermission: boolean;
	fields: Record<string, any>;
}

const ApprovalContentHeader: React.FC<ApprovalContentHeaderProps> = ({
	dateOrder,
	orderNumber,
	orderStatus,
	submittedBy,
	hasNavigationCompassEditPermission,
	fields,
}) => {
	// Generating Dynamic URL for store
	const fullHostName = window.location.hostname.toUpperCase();
	let endpoint = "";
	if (fullHostName.includes("BETA")) {
		endpoint = "https://compass.beta.togacommerce.com";
	} else if (fullHostName.includes(".COM")) {
		endpoint = "https://compass.togacommerce.com";
	} else {
		endpoint = "http://compass";
	}

	const hostName = localStorage.getItem("hostName") || "";
	const { convertedStatus, color } = convertStatus(orderStatus, hostName);

	const disabledLink =
		!["pendingApproval", "pendingInitialApproval"].includes(orderStatus) ||
		!hasNavigationCompassEditPermission;

	const modalTitleLabel = fields[0].label;
	const modalSubTitleLabel = fields[1].label;
	const modalSupportingLabel = fields[2].label;
	const modalOrderNumberLabel = fields[3].label;

	return (
		<motion.div className="flex flex-col gap-2 w-full max-sm:p-2">
			<div className="flex sm:flex-row flex-col justify-between sm:items-center w-full ">
				<div className="flex flex-row items-center gap-2 max-sm:flex-col max-sm:items-start">
					<BaseText
						size={"text-lg"}
						color={"text-navy-500 font-semibold"}
						text={modalTitleLabel || "—"}
						fontFamily={"font-inter-semibold"}
						tag={"h1"}
					/>
					<StatusBadge
						statusName={convertedStatus || "—"}
						colorHex={color}
						additionalClasses="flex items-center"
						fontFamily={"font-inter-medium"}
					/>
				</div>
				<div className="flex items-center gap-2 font-inter-regular sm:my-0 my-2">
					<Link
						to={`${endpoint}/approveorder?orderNumber=${orderNumber}`}
						className={`${
							disabledLink
								? "text-navy-300 cursor-not-allowed"
								: "text-hyperlink hover:text-primary-800 hover:underline group"
						} flex items-center gap-1 text-sm   `}
						onClick={(e) => {
							if (disabledLink) {
								e.preventDefault();
							}
						}}
					>
						{!disabledLink && (
							<>
								<span className="group-hover:text-primary-800">
									Edit on TOGa Commerce
								</span>
								<span className="group-hover:text-primary-800">
									{getFontAwesomeIcon("penLine", "regular")}
								</span>
							</>
						)}
					</Link>
				</div>
			</div>

			<div className="sm:flex justify-between items-center w-full ">
				<div className="flex items-center">
					<p className="text-sm font-inter-regular max-sm:text-left max-sm:mb-2">
						{modalSubTitleLabel} {dateOrder}
						<span className="italic text-dark-5 ml-1">
							{modalSupportingLabel} {submittedBy}
						</span>
					</p>
				</div>
				<div className="flex flex-row gap-1">
					<p className="text-sm font-inter-medium">
						{modalOrderNumberLabel}
					</p>
					<p className="text-sm font-inter-regular">{orderNumber}</p>
				</div>
			</div>
		</motion.div>
	);
};

export default ApprovalContentHeader;
