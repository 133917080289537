import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";

// HOOKS AND FUNCTION CALLS
import {
	getSingleVendorItem,
	updateVendorItemDetails,
} from "../../../api/vendorItemsApi";
import { useItemsContext } from "../../../../../hooks/dataTableHooks/useItemsContext";
import { useToaster } from "../../../../../hooks/useToasterContext";

// COMPONENTS
import BaseButton from "../../../../../components/ui/BaseButton";
import BaseText from "../../../../../components/ui/BaseText";
import ToggleButton from "../../../../../components/ui/ToggleButton";
import BasePill from "../../../../../components/ui/BasePill";
import EventLog from "../../../../../components/ui/EventLog/EventLog";
import BaseContainer from "../../../../../components/ui/BaseContainer";
import VerticalImageCarousel from "../../../../../components/ui/VerticalImageCarousel";
import ModalContentSection from "../../../../../components/layout/Modal/ModalContentSection";
import dummyItemFields from "../../../../Items/view/modals/viewEdit/DUMMYITEMFIELDS.json";
import dummyVendorItemFields from "./DUMMYVENDORITEMFIELDS.json";
import BorderGradientContainer from "../../../../../components/ui/BorderGradientContainer";
import Image from "../../../../../components/layout/ImageComponent";
import placeholderImage from "../../../../../assets/placeholder-no-image-available.png";
import SupplyFormGuardrail from "../../../../../components/misc/SupplyFormGuardrail";
import { getFontAwesomeIcon } from "../../../../../utils";
import { LoadingMessage } from "../../../../../components/misc";
import {
	extendedEaseOutVariant,
	fadeInOutVariants,
	slideOutMenuOverlayVariant,
	slideOutMenuVariants,
} from "../../../../../motionVariants";
import { getData, getIntegrationData } from "../../../../Items/api/itemsApi";
import {
	EditVendorOptions,
	VendorItemsModalContentTemplateProps,
	TimelineItem,
	Guardrail,
	ImageType,
	PersonaObject,
} from "../types";

type FormData = {
	vendor?: {
		uuid: string;
		name: string[];
	};
	"Vendor Part #": string;
	"Vendor Part Description": string;
	Item: string;
	"Unit Cost": number;
	"Lease Cost per Month": number;
	"Lease Duration Months": number;
	"Lease Cost per Quarter": number;
	"Lease Duration Quarters": number;
	overridePoSubmissionIntegration: { uuid: string; name: string[] } | null;
	"ASN Transmission Integration": string;
	"Restrict to Persona": Array<{ value: string; label: string }>;
	Kits: string;
};

const VendorItemsModalContentTemplate: React.FC<
	VendorItemsModalContentTemplateProps
> = ({
	modalRef,
	hasBorder = false,
	borderColor = "primary",
	onClose,
	isFromVendorItemsPage,
}) => {
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
	const [toggleStatus, setToggleStatus] = useState<boolean>(false);
	const [toggleDisabled, setToggleDisabled] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isEditMode, setIsEditMode] = useState<boolean>(false);
	const [vendorItem, setVendorItem] = useState<any>("");
	const [itemImages, setItemImages] = useState<any>("");
	const { activeUuid } = useItemsContext();
	const { addToaster, clearAllToasters } = useToaster();
	const [guardrail, setGuardrail] = useState<Guardrail | null>(null);
	const [originalItemData, setOriginalItemData] = useState<any>("");
	const [vendorsOptions, setVendorsOptions] =
		useState<EditVendorOptions | null>(null);
	const [opsimOptions, setOpsimOptions] = useState<any | null>(null);
	const [refetchData, setRefetchData] = useState<boolean>(false);

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			try {
				//@ts-ignore
				const result = await getSingleVendorItem(activeUuid);
				const overridePoSubmissionIntegration =
					result.overridePoSubmissionIntegration;

				if (result.vendor) {
					result.vendor = {
						uuid: result.vendor.uuid,
						name: [
							`${result.vendor.name}`,
							`${result.vendor.number}`,
						],
					};
				}

				if (
					overridePoSubmissionIntegration &&
					overridePoSubmissionIntegration.type
				) {
					result.overridePoSubmissionIntegration = {
						uuid: overridePoSubmissionIntegration.uuid,
						name: [
							`${overridePoSubmissionIntegration.type}`,
							`${overridePoSubmissionIntegration.name}`,
						],
					};
				}

				if (result.vendorPartDescription === "__cleared") {
					result.vendorPartDescription = "";
				}
				if (result.item.personaItems.length > 0) {
					result.item.restrictToPersona = Array.from(
						new Set(
							result.item.personaItems.map(
								(persona: PersonaObject) => persona.persona.name
							)
						)
					);
				}

				setVendorItem(result);
				setOriginalItemData(result);
				setToggleStatus(result.isActive);
				setItemImages(result.item.itemImages);

				const vendorsData = await getData("vendors", [
					"uuid",
					"name",
					"number",
				]);

				const vendorsNameData = vendorsData.map((vendor) => ({
					uuid: vendor.uuid,
					name: [`${vendor.name}`, `${vendor.number}`],
				}));

				setVendorsOptions({
					vendorsData: vendorsNameData,
				});
			} catch (error) {
				addToaster({
					isOpen: true,
					message: "Failed to fetch vendor item details.",
					status: "error",
				});
			} finally {
				setIsLoading(false);
			}
		};
		fetchData();
	}, [activeUuid, refetchData]);

	const formMethods = useForm<FormData>({
		mode: "all",
		defaultValues: vendorItem || {},
	});

	const {
		reset,
		handleSubmit,
		watch,
		setValue,
		formState: { dirtyFields, errors, isValid },
	} = formMethods;
	const initialItemRef = useRef(vendorItem);
	const vendor = watch("vendor");
	const clearField = () => {
		setValue(
			"overridePoSubmissionIntegration",
			{ uuid: "", name: [] },
			{
				shouldDirty: false,
			}
		);
	};

	useEffect(() => {
		if (vendor) {
			const fetchData = async () => {
				try {
					const vendorUuid = vendor.uuid;
					// Pass the vendorId based on the selection from the dropdown
					const overridePoSubmissionIntegrationMethods =
						await getIntegrationData(vendorUuid);

					const overridePoSubmissionIntegrationMethodData =
						overridePoSubmissionIntegrationMethods.map(
							(integration) => ({
								uuid: integration.uuid,
								name: [
									`${integration.type}`,
									`${integration.name}`,
								],
							})
						);

					setOpsimOptions({
						overridePoSubmissionIntegrationMethods:
							overridePoSubmissionIntegrationMethodData,
					});
				} catch (error) {
					console.error("Error fetching data:", error);
				}
			};
			if (dirtyFields.vendor) {
				clearField();
			}

			fetchData();
		} else if (vendor === null) {
			setOpsimOptions(null);
			clearField();
		}
	}, [vendor]);

	const onFormSubmit = async (data: FormData) => {
		const hasErrors = Object.keys(errors).length > 0;
		if (hasErrors) {
			addToaster({
				isOpen: true,
				message:
					"Please correct the highlighted fields before submitting.",
				status: "error",
			});
			return;
		}

		let overridePoSubmissionIntegration;

		if (
			data.overridePoSubmissionIntegration &&
			data.overridePoSubmissionIntegration.uuid === ""
		) {
			data.overridePoSubmissionIntegration = null;
		} else if (data.overridePoSubmissionIntegration) {
			overridePoSubmissionIntegration = {
				uuid: data.overridePoSubmissionIntegration.uuid,
			};
		}
		let newData = {
			...data,
			isActive: toggleStatus,
			overridePoSubmissionIntegration,
		};
		// Removing Item Details from the data to be sent to the API
		//@ts-ignore
		const { item: itemFromNewData, ...newDataWithoutItem } = newData;
		const { item: itemFromVendorItem, ...newVendorItemWithoutItem } =
			vendorItem;
		const uuid = vendorItem.uuid;

		try {
			const response = await updateVendorItemDetails(
				uuid,
				vendorItem,
				newData
			);
			if (response.status === 200) {
				setRefetchData(!refetchData);
				setIsEditMode(!isEditMode);
				setVendorItem(newData);
				addToaster({
					isOpen: true,
					message: "Item details updated successfully",
					status: "success",
				});
			} else {
				addToaster({
					isOpen: true,
					message: "Failed to update item details",
					status: "error",
				});
			}
		} catch (error) {
			addToaster({
				isOpen: true,
				message: "An error occurred while updating item details",
				status: "error",
			});
		}
	};

	useEffect(() => {
		if (vendorItem && vendorItem !== initialItemRef.current) {
			reset({
				...formMethods.getValues(),
				...vendorItem,
			});
			initialItemRef.current = vendorItem;
		}
	}, [vendorItem, reset, formMethods]);

	const handleActiveStateGuardrail = () => {
		if (toggleStatus !== vendorItem.isActive || guardrail) {
			setGuardrail({
				isOpen: true,
				messageOne: `Save changes, including Active Status updates?`,
				messageTwo: `This will save your changes and set the Active Status to ${!originalItemData.isActive}.`,
			});
		} else {
			handleSubmit(onFormSubmit)();
			setGuardrail(null);
		}
	};

	const handleGuardrailSave = () => {
		handleSubmit(onFormSubmit)();
		setGuardrail(null);
	};

	const handleClose = () => {
		const currentFormValues = formMethods.getValues();
		const isFormDirty =
			JSON.stringify(currentFormValues) !==
			JSON.stringify(initialItemRef.current);
		if (isFormDirty && isEditMode) {
			setGuardrail({
				isOpen: true,
				messageOne: `Do you want to save the changes made to ${vendorItem?.item?.title}?`,
				messageTwo: "Your changes will be lost if you don’t save them.",
			});
		} else {
			setIsMenuOpen(false);
			clearAllToasters();
			onClose();
			setGuardrail(null);
			document.body.classList.remove("overflow-hidden");
		}
	};

	const handleToggleCancel = () => {
		const currentFormValues = formMethods.getValues();
		const isFormDirty =
			JSON.stringify(currentFormValues) !==
			JSON.stringify(initialItemRef.current);
		if (isFormDirty && isEditMode) {
			setGuardrail({
				isOpen: true,
				messageOne: `Do you want to save the changes made to ${vendorItem?.item?.title}?`,
				messageTwo: "Your changes will be lost if you don’t save them.",
			});
		} else {
			setToggleStatus(vendorItem.isActive);
			reset();
			setToggleDisabled(!toggleDisabled);
			setIsEditMode(false);
			setGuardrail(null);
		}
	};

	const handleGuardrailDoNotSave = () => {
		setToggleStatus(vendorItem.isActive);
		reset();
		setToggleDisabled(!toggleDisabled);
		setIsEditMode(false);
		setGuardrail(null);
	};

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	const formClassNames =
		"flex justify-around w-full max-md:flex-col overflow-visible gap-10";
	const modalBorderClassNames = `overflow-scroll bg-white min-w-90% rounded-3xl border-2 max-h-[95dvh]  ${
		isMenuOpen ? " overflow-hidden pt-0" : ` pt-0 `
	}  ${
		hasBorder ? `border-${borderColor}` : "border-transparent"
	} overflow-visible`;

	// HEADER CLASSES:
	const headerContainerClassNames =
		"flex flex-col items-between justify-between sticky top-[-5px] bg-white max-lg:flex-col min-h-32 pt-2";
	const headerTitleAndButtonsContainerClassNames =
		"flex items-start max-lg:flex-col justify-between w-full pb-2";
	const headerTitlesContainerClassNames =
		"flex w-1/2 max-lg:w-full max-lg:ml-1 justify-between";
	const headerButtonContainerClassNames =
		"flex w-1/3 justify-end  max-lg:pt-3 max-lg:justify-start";
	const mobileExitButtonClassNames =
		"hidden max-lg:flex justify-start py-2 px-4";

	// SLIDEOUT MENU CLASSES:
	const slideOutMenuClassNames = `slide-out-menu bg-white w-1/2 min-w-[300px] max-w-[500px] absolute top-0 left-0 z-40`;
	const overlayClassNames = `bg-black absolute z-1 w-full left-0 top-0 cursor-pointer `;
	const overlayHeight = modalRef.current
		? modalRef.current.scrollHeight + "px"
		: "100%";
	const menuItemClassNames = "sticky min-h-full max-h-full mb-20";
	const itemCreatedText = "Item Created";
	const eventLogTitle = "Event Log";

	if (isLoading) {
		return (
			<div
				className={` min-full min-h-dvh
				 flex justify-center items-center bg-white`}
			>
				<div className="h-full">
					<LoadingMessage message={""} />
				</div>
			</div>
		);
	}

	return (
		<div ref={modalRef} className={modalBorderClassNames}>
			<header aria-label="vendor items modal header.">
				{guardrail && (
					<>
						<div
							className={`bg-black opacity-10 absolute z-40 w-full left-0 top-0 `}
							style={{ height: "100vh", width: "100vw" }}
						></div>
						<SupplyFormGuardrail
							messages={guardrail}
							onDoNotSave={handleGuardrailDoNotSave}
							onCancel={() => setGuardrail(null)}
							onSave={handleGuardrailSave}
						/>
					</>
				)}
				<header
					className={headerContainerClassNames}
					aria-label="Vendor Items modal header."
				>
					<div className={mobileExitButtonClassNames}>
						{!isFromVendorItemsPage && (
							<BaseButton
								text="Item"
								hoverBackground="none"
								borderColor="none"
								additionalClasses={`py-0 mx-2 text-primary hover:text-supply-blue-300`}
								shape="rounded"
								icon={getFontAwesomeIcon(
									"chevronLeft",
									"regular"
								)}
								as="button"
								iconClasses="flex items-center text-xxs pr-1"
								fontFamily="font-omnes-regular"
								onClick={handleClose}
							/>
						)}
					</div>
					<div className="flex flex-col px-6 py-4">
						<div
							className={headerTitleAndButtonsContainerClassNames}
						>
							<div className={headerTitlesContainerClassNames}>
								<div className="flex items-center">
									{
										<BasePill
											text={
												<BaseText
													size={"text-sm"}
													color={"text-slate-950"}
													text={"Vendor Item"}
													fontFamily={
														"font-omnes-medium"
													}
													tag={"h1"}
													additionalClasses="text-nowrap"
												/>
											}
											firstIcon={getFontAwesomeIcon(
												"store",
												"solid"
											)}
											hoverBackgroundColor="none"
											additionalClasses="px-2.5 py-1 h-26px"
											shape="rounded-full"
											color="bg-supply-blue-50"
											borderColor="transparent"
											type={"span"}
											hasMobileStyle={false}
											iconColor="text-slate-950"
										/>
									}
									<div className="px-2 text-left">
										{
											<BaseText
												size={"text-16px"}
												color={"text-navy-350"}
												text={` ${vendorItem?.vendor?.name} - Vendor Part #: ${vendorItem?.vendorPartNumber}`}
												fontFamily={""}
												tag={"h2"}
											/>
										}
									</div>
								</div>
								<div className={mobileExitButtonClassNames}>
									{isFromVendorItemsPage && (
										<BaseButton
											text=""
											bgColor="bg-white"
											fontColor="text-navy-300"
											hoverFontColor="hover:text-navy-500"
											additionalClasses={`p-2 mx-4px`}
											shape="rounded-full"
											iconAriaLabel="Close"
											icon={getFontAwesomeIcon(
												"Xmark",
												"regular"
											)}
											as="button"
											onClick={handleClose}
											iconClasses="text-lg pr-0 size-3"
										/>
									)}
								</div>
							</div>

							<div className="hidden text-left max-lg:flex ml-1 pt-3">
								<BaseText
									size={"text-22px"}
									color={"text-navy-500"}
									text={
										vendorItem?.vendorPartDescription ||
										vendorItem?.item?.title
									}
									fontFamily={"font-omnes-medium"}
									tag={"h2"}
								/>
							</div>
							<div className={headerButtonContainerClassNames}>
								{
									<ToggleButton
										initialStatus={toggleStatus}
										onClick={() => {
											setToggleStatus(!toggleStatus);
										}}
										textPosition={"right"}
										textSize={"md"}
										activeColorBackground="bg-mint-600"
										inactiveColorBackground="bg-crimson-500"
										activeTextColor="text-mint-700"
										inactiveTextColor="text-crimson-700"
										hasDisabledStatus={!isEditMode}
										additionalClasses={`w-1/4 py-2`}
										hasDivider={true}
										fontFamily="omnes-medium"
										activeLabel={"Active"}
										inactiveLabel={"Inactive"}
										id={"isActive"}
										pillHeight="h-7"
										smallToggle={false}
										borderStyle={false}
									/>
								}
								{isEditMode ? (
									<div className="flex">
										{
											<BaseButton
												text="Cancel"
												bgColor="bg-white"
												hoverBackground="hover:bg-primaryHoverLight"
												fontColor="text-primary"
												borderColor="border-1 border-primary"
												additionalClasses={`py-1 px-3 mx-2 max-sm:mx-1`}
												shape="rounded-full"
												as="button"
												onClick={handleToggleCancel}
												fontFamily="font-omnes-medium"
												buttonSize="w-67px h-26px"
												fontSize="text-sm"
											/>
										}
										{
											<BaseButton
												type="button"
												text="Save"
												bgColor="bg-primary"
												fontColor="text-white"
												hoverBackground="hover:bg-primaryHover"
												additionalClasses={`py-1 px-3 mx-2 max-sm:mx-1`}
												shape="rounded-full"
												as="button"
												fontFamily="font-omnes-medium"
												onClick={
													handleActiveStateGuardrail
												}
												buttonSize="w-67px h-26px"
												fontSize="text-sm"
											/>
										}
									</div>
								) : (
									<div className="flex">
										{
											<BaseButton
												text="Event Log"
												bgColor="bg-white"
												hoverBackground="hover:bg-primaryHoverLight"
												fontColor="text-primary"
												borderColor="border-1 border-primary"
												additionalClasses={`text-nowrap py-1 px-3 mx-5px max-sm:size-8 max-sm:justify-center text-14px`}
												shape="rounded-full"
												icon={getFontAwesomeIcon(
													"clock",
													"regular"
												)}
												iconBreakpoint="hidden max-sm:flex max-sm:pr-0"
												as="button"
												textBreakpoint="max-sm:hidden"
												onClick={toggleMenu}
												fontFamily="font-omnes-medium"
												buttonSize="w-85px h-26px"
												iconClasses="text-primary"
											/>
										}
										{
											<BaseButton
												text="Edit"
												bgColor="bg-primary"
												fontColor="text-white"
												hoverBackground="hover:bg-primaryHover"
												buttonSize="w-85px h-26px"
												additionalClasses={`text-nowrap mx-5px max-sm:size-8 max-sm:justify-center text-14px`}
												shape="rounded-full"
												icon={getFontAwesomeIcon(
													"edit",
													"regular"
												)}
												iconBreakpoint="max-sm:pr-0"
												as="button"
												textBreakpoint="max-sm:hidden"
												onClick={() => {
													setIsEditMode(true);
													setToggleDisabled(false);
												}}
												fontFamily="font-omnes-medium"
												iconClasses="max-sm:pr-0 pr-2 text-white"
											/>
										}
									</div>
								)}
								<div className="flex max-lg:hidden">
									<BaseButton
										text=""
										additionalClasses={`p-2 mx-4px`}
										iconAriaLabel="Close"
										icon={getFontAwesomeIcon(
											"Xmark",
											"regular"
										)}
										as="button"
										onClick={handleClose}
										iconClasses="flex items-center text-lg pr-0 size-3 text-navy-300 hover:text-navy-500"
									/>
								</div>
							</div>
						</div>
						<div className="text-left max-lg:hidden">
							<BaseText
								size={"text-22px"}
								color={"text-navy-500"}
								text={
									vendorItem?.vendorPartDescription ||
									vendorItem?.item?.title
								}
								fontFamily={"font-omnes-medium"}
								tag={"h2"}
							/>
						</div>
					</div>

					<div className="flex pb-4 px-6 max-lg:hidden">
						{!isFromVendorItemsPage && (
							<BaseButton
								text="Item"
								hoverBackground="none"
								borderColor="none"
								additionalClasses={`py-0 mx-2 text-primary hover:text-supply-blue-300`}
								shape="rounded"
								icon={getFontAwesomeIcon(
									"chevronLeft",
									"regular"
								)}
								as="button"
								iconClasses="flex items-center text-xxs pr-1"
								fontFamily="font-omnes-regular"
								onClick={handleClose}
							/>
						)}
					</div>
				</header>
			</header>

			<motion.div {...fadeInOutVariants}>
				<>
					<div key="randomKey1" className="overflow-hidden">
						{!isEditMode ? (
							<AnimatePresence>
								<motion.div
									key="motionDiv1"
									{...extendedEaseOutVariant}
								>
									<div className="flex justify-around w-full max-md:flex-col gap-8 px-10 max-lg:px-2">
										<BaseContainer
											columnContainerClasses="flex flex-col w-1/2 mr-2 max-md:w-full"
											columnShape="rounded-full"
											borderColor="transparent"
											isDisabled={false}
											children={
												<>
													{itemImages.length != 0 ? (
														<div className="flex items-start justify-center flex-col mb-6">
															<VerticalImageCarousel
																imageUrls={itemImages.map(
																	(
																		image: ImageType
																	) =>
																		image
																			.highImage
																			.sourceUrl
																)}
																containerMaxHeight="max-h-96"
																containerMinHeight="min-h-96"
																enabled={true}
															/>
														</div>
													) : (
														<div className="flex items-center justify-center flex-col mb-6">
															<Image
																imageUrl={
																	placeholderImage
																}
																altText="No Image Available"
																className="items-center size-54"
															/>
														</div>
													)}
												</>
											}
										/>
										<BorderGradientContainer
											containerWidth="w-1/2 max-md:w-full"
											startBorderColor="from-gradientStart"
											endBorderColor="to-gradientEnd"
											containerShape="rounded-full"
											children={
												<BaseContainer
													columnContainerClasses="flex flex-col py-3.5 px-6 h-full max-md:w-full"
													backgroundColor="bg-white"
													borderColor="transparent"
													columnShape="rounded-full"
													isDisabled={false}
													children={
														<>
															<ModalContentSection
																sectionTitle={
																	<BaseText
																		size={
																			"text-md"
																		}
																		color={
																			"text-slate-950"
																		}
																		text={
																			"Overview"
																		}
																		fontFamily={
																			"font-omnes-medium"
																		}
																		tag={
																			"h2"
																		}
																		additionalClasses="pb-1"
																	/>
																}
																valueType={
																	"detail"
																}
																data={
																	vendorItem
																}
																dataLabel="OVERVIEW"
																fields={
																	dummyVendorItemFields
																		.DUMMYVENDORITEMFIELDS[0]
																		.OVERVIEW
																}
																itemContainerClasses="w-full py-1"
																sectionContainerClasses="flex flex-col items-start"
																detailsContainerClasses="items-start w-full"
																isDisabled={
																	false
																}
															/>
															<ModalContentSection
																sectionTitle={
																	<BaseText
																		size={
																			"text-md"
																		}
																		color={
																			"text-slate-950"
																		}
																		text={
																			"Costs"
																		}
																		fontFamily={
																			"font-omnes-medium"
																		}
																		tag={
																			"h2"
																		}
																		additionalClasses="pb-1"
																	/>
																}
																valueType={
																	"detail"
																}
																data={
																	vendorItem
																}
																dataLabel="COSTS"
																fields={
																	dummyVendorItemFields
																		.DUMMYVENDORITEMFIELDS[1]
																		.COSTS
																}
																itemContainerClasses="w-full py-1"
																sectionContainerClasses="flex flex-col items-start pt-28px"
																detailsContainerClasses="grid grid-cols-2 items-start w-full max-lg:flex max-lg:flex-col"
																isDisabled={
																	false
																}
															/>
															<ModalContentSection
																sectionTitle={
																	<BaseText
																		size={
																			"text-md"
																		}
																		color={
																			"text-slate-950"
																		}
																		text={
																			"Attributes"
																		}
																		fontFamily={
																			"font-omnes-medium"
																		}
																		tag={
																			"h2"
																		}
																		additionalClasses="pb-1"
																	/>
																}
																valueType={
																	"detail"
																}
																data={
																	vendorItem
																}
																dataLabel="ATTRIBUTES"
																fields={
																	dummyVendorItemFields
																		.DUMMYVENDORITEMFIELDS[2]
																		.ATTRIBUTES
																}
																itemContainerClasses="w-full py-1"
																sectionContainerClasses="flex flex-col items-start pt-28px"
																detailsContainerClasses="items-start w-full"
																isDisabled={
																	false
																}
															/>
														</>
													}
												/>
											}
										/>
									</div>
								</motion.div>
							</AnimatePresence>
						) : (
							<AnimatePresence>
								<motion.div
									key="motionDiv2"
									{...extendedEaseOutVariant}
								>
									{/* FormProvider is providing context, allows BaseInput modal to access form methods */}
									<FormProvider {...formMethods}>
										<form
											className={formClassNames}
											onSubmit={handleSubmit(
												onFormSubmit
											)}
										>
											<div className="flex justify-around w-full max-md:flex-col gap-8 px-10 max-lg:px-2">
												<BaseContainer
													columnContainerClasses="flex flex-col w-1/2 mr-2 max-md:w-full"
													columnShape="rounded-full"
													borderColor="transparent"
													isDisabled={false}
													children={
														<>
															{itemImages.length !=
															0 ? (
																<div className="flex items-start justify-center flex-col mb-6">
																	<VerticalImageCarousel
																		imageUrls={itemImages.map(
																			(
																				image: ImageType
																			) =>
																				image
																					.highImage
																					.sourceUrl
																		)}
																		containerMaxHeight="max-h-96"
																		containerMinHeight="min-h-96"
																		enabled={
																			false
																		}
																	/>
																</div>
															) : (
																<div className="flex items-center justify-center flex-col mb-6">
																	<Image
																		imageUrl={
																			placeholderImage
																		}
																		altText="No Image Available"
																		className="items-center size-54"
																	/>
																</div>
															)}
														</>
													}
												/>
												<BorderGradientContainer
													containerWidth="w-1/2 max-md:w-full"
													startBorderColor="from-gradientStart"
													endBorderColor="to-gradientEnd"
													containerShape="rounded-full"
													children={
														<BaseContainer
															columnContainerClasses="flex flex-col py-3.5 px-6 h-full max-md:w-full"
															backgroundColor="bg-white"
															borderColor="transparent"
															columnShape="rounded-full"
															isDisabled={false}
															children={
																<>
																	<ModalContentSection
																		sectionTitle={
																			<BaseText
																				size={
																					"text-md"
																				}
																				color={
																					"text-slate-950"
																				}
																				text={
																					"Overview"
																				}
																				fontFamily={
																					"font-omnes-medium"
																				}
																				tag={
																					"h2"
																				}
																				additionalClasses="pb-1"
																			/>
																		}
																		valueType="input"
																		data={
																			vendorItem
																		}
																		dataLabel="OVERVIEW"
																		fields={
																			dummyVendorItemFields
																				.DUMMYVENDORITEMFIELDS[0]
																				.OVERVIEW
																		}
																		selectOptions={
																			vendorsOptions
																		}
																		itemContainerClasses="w-full "
																		sectionContainerClasses="flex flex-col items-start"
																		detailsContainerClasses="items-start w-full"
																		isDisabled={
																			false
																		}
																	/>
																	<ModalContentSection
																		sectionTitle={
																			<BaseText
																				size={
																					"text-md"
																				}
																				color={
																					"text-slate-950"
																				}
																				text={
																					"Costs"
																				}
																				fontFamily={
																					"font-omnes-medium"
																				}
																				tag={
																					"h2"
																				}
																				additionalClasses="pb-1"
																			/>
																		}
																		valueType={
																			"input"
																		}
																		data={
																			vendorItem
																		}
																		dataLabel="COSTS"
																		fields={
																			dummyVendorItemFields
																				.DUMMYVENDORITEMFIELDS[1]
																				.COSTS
																		}
																		itemContainerClasses="w-full"
																		sectionContainerClasses="flex flex-col items-start pt-28px"
																		detailsContainerClasses="grid grid-cols-2 items-start w-full max-lg:flex max-lg:flex-col"
																		isDisabled={
																			false
																		}
																	/>
																	<ModalContentSection
																		sectionTitle={
																			<BaseText
																				size={
																					"text-md"
																				}
																				color={
																					"text-slate-950"
																				}
																				text={
																					"Attributes"
																				}
																				fontFamily={
																					"font-omnes-medium"
																				}
																				tag={
																					"h2"
																				}
																				additionalClasses="pb-1"
																			/>
																		}
																		valueType="input"
																		data={
																			vendorItem
																		}
																		selectOptions={
																			opsimOptions
																		}
																		dataLabel="ATTRIBUTES"
																		fields={
																			dummyVendorItemFields
																				.DUMMYVENDORITEMFIELDS[2]
																				.ATTRIBUTES
																		}
																		itemContainerClasses="w-full"
																		sectionContainerClasses="flex flex-col items-start pt-28px"
																		detailsContainerClasses="items-start w-full"
																		isDisabled={
																			false
																		}
																	/>
																</>
															}
														/>
													}
												/>
											</div>
										</form>
									</FormProvider>
								</motion.div>
							</AnimatePresence>
						)}
						<div className="flex flex-col items-start my-8 px-6">
							<BaseText
								size={"text-lg"}
								color={"text-navy-500"}
								text={"More Info"}
								fontFamily={"font-omnes-medium"}
								tag={"h2"}
								additionalClasses={"mb-4"}
							/>
							<BaseContainer
								columnContainerClasses="flex flex-col py-18px px-22px w-full"
								backgroundColor="bg-navy-100"
								columnShape="rounded-full"
								borderColor="transparent"
								children={
									<>
										<ModalContentSection
											sectionTitle={
												<BaseText
													size={"text-md"}
													color={"text-navy-270"}
													text={"Content"}
													fontFamily={
														"font-omnes-medium"
													}
													tag={"h2"}
													additionalClasses="pb-1"
												/>
											}
											valueType={"detail"}
											data={vendorItem.item}
											dataLabel="CONTENT"
											fields={
												dummyItemFields
													.DUMMYITEMFIELDS[5]
													.VENDORITEMINFO
											}
											itemContainerClasses="w-full py-1"
											sectionContainerClasses="flex flex-col items-start pt-2"
											detailsContainerClasses="flex flex-col items-start w-full"
											mobileBreakpoint="max-md:flex-col"
											isDisabled={true}
										/>
										<ModalContentSection
											sectionTitle={
												<BaseText
													size={"text-md"}
													color={"text-navy-270"}
													text={"More Info"}
													fontFamily={
														"font-omnes-medium"
													}
													tag={"h2"}
													additionalClasses="pb-1"
												/>
											}
											valueType={"detail"}
											data={vendorItem.item}
											dataLabel="MOREINFO"
											fields={
												dummyItemFields
													.DUMMYITEMFIELDS[4].MOREINFO
											}
											itemContainerClasses="w-full py-1"
											sectionContainerClasses="flex flex-col items-start py-2"
											detailsContainerClasses="flex flex-col items-start w-full"
											mobileBreakpoint="max-md:flex-col"
											isDisabled={true}
										/>
									</>
								}
							/>
						</div>
					</div>
				</>
			</motion.div>

			<>
				<AnimatePresence>
					{isMenuOpen && (
						<motion.div
							{...slideOutMenuVariants}
							className={slideOutMenuClassNames}
							style={{
								height: overlayHeight,
							}}
						>
							<div className={menuItemClassNames}>
								<EventLog
									title={eventLogTitle}
									isOpen={isMenuOpen}
									parentData={vendorItem}
									slug={"vendor-items"}
									hasActivityToggle={false}
								/>
							</div>
						</motion.div>
					)}
				</AnimatePresence>
				<AnimatePresence>
					{isMenuOpen && (
						<motion.div
							initial={slideOutMenuOverlayVariant.initial}
							animate={slideOutMenuOverlayVariant.animate}
							exit={slideOutMenuOverlayVariant.exit}
							transition={slideOutMenuOverlayVariant.transition}
							onClick={toggleMenu}
							className={overlayClassNames}
							style={{
								height: overlayHeight,
							}}
						></motion.div>
					)}
				</AnimatePresence>
			</>
		</div>
	);
};

export default VendorItemsModalContentTemplate;
